import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCrown } from '@fortawesome/free-solid-svg-icons'
import './lobby.css'
// import './counter.scss'

const Lobby = ({
  game,
  sendMessage,
  thisPlayer,
  setThisPlayer,
  ws,
  setWs,
  connectWebSocket,
  isGameStateRoom,
}) => {
  // //console.log("ws is now in lobby:")
  // //console.log(ws)

  //console.log('We are in lobby now, with game:')
  //console.log(game)
  //console.log('and thisPlayer:')
  //console.log(thisPlayer)

  const [newRoundClicked, setNewRoundClicked] = useState(false)
  const [countdown, setCountdown] = useState(5)

  const handleReady = () => {
    if (thisPlayer.ready == true) {
      //console.log('About to send NotReady for thisPlayer.name: ' + thisPlayer.name)
      setThisPlayer({ ...thisPlayer, ready: false })
      sendMessage({
        event: 'playerNotReady',
        player: { name: thisPlayer.name },
      })
    } else {
      //console.log('About to send Ready for thisPlayer.name: ' + thisPlayer.name)
      setThisPlayer({ ...thisPlayer, ready: true })
      sendMessage({ event: 'playerReady', player: { name: thisPlayer.name } })
    }
  }

  const handleStart = () => {
    //console.log('About to send Start for thisPlayer.name: ' + thisPlayer.name)
    sendMessage({ event: 'newRound', player: { name: thisPlayer.name } })
    setNewRoundClicked(true)
  }
  useEffect(() => {
    if (countdown > 0 && isGameStateRoom) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)

      return () => clearInterval(timer)
    } else {
      setCountdown(5)
    }
  }, [isGameStateRoom])

  return (
    <div className="lobby">
      <div className="header">
        <div className="info-container">
          <h3 className="room-info">
            <p>Oda: {game.roomCode || '3ASX'}</p>
            <p>Başkan: {game.host.name}</p>
          </h3>
          <div className="player_info">
            <p className="player_name">{thisPlayer.name}</p>
            <img className="player_img" src={thisPlayer.avatar} />
          </div>
        </div>
      </div>
      <div className="counter-container">
        <div className="counter"></div>
      </div>
      <h1>Odadakiler</h1>
      <div className="lobby__players">
        {game?.players?.map((player, i) => (
          <div key={i} className="lobby__player">
            <img src={player.avatar} />
            <p className="lobby__player-name">{player.name}</p>
            <div className="lobby__player-ready">
              {game?.host.name == player.name && (
                <FontAwesomeIcon icon={faCrown} style={{ color: 'yellow' }} />
              )}

              {player.ready && !(game?.host.name == thisPlayer.name) ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <div> </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {!(game?.host.name == thisPlayer.name) && (
        <button
          className="lobby-button"
          onClick={handleReady}
          disabled={isGameStateRoom}
          style={{
            pointerEvents: isGameStateRoom && 'none',
          }}
        >
          {thisPlayer?.ready && !isGameStateRoom && <p>Hazır değilim</p>}
          {!thisPlayer?.ready && !isGameStateRoom && <p>Hazırım</p>}
          {isGameStateRoom && <p>...{countdown}...</p>}
        </button>
      )}
      {game?.host.name == thisPlayer.name && (
        <div className="lobby__host-controls">
          <button
            className="lobby-button"
            onClick={handleStart}
            disabled={isGameStateRoom}
            style={{
              pointerEvents: isGameStateRoom && 'none',
            }}
          >
            {/* {newRoundClicked ? 'Başlatılıyor...' : 'Başlat'} */}
            {isGameStateRoom ? <p>...{countdown}...</p> : 'Başlat'}
          </button>
        </div>
      )}
    </div>
  )
}

export default Lobby
