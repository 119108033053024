import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLaugh as farFaLaugh,
  faSmile as farFaSmile,
  faMeh as farFaMeh,
  faFrown as farFaFrown,
  faDizzy as farFaDizzy,
} from '@fortawesome/free-regular-svg-icons'

import {
  faLaugh as fasFaLaugh,
  faSmile as fasFaSmile,
  faMeh as fasFaMeh,
  faFrown as fasFaFrown,
  faDizzy as fasFaDizzy,
} from '@fortawesome/free-solid-svg-icons'

import './result.scss'
// import './tooltip.scss'

const Result = ({
  game,
  setGame,
  sendMessage,
  thisPlayer,
  setThisPlayer,
  isGameStateRoom,
}) => {
  // console.log('We are in RESULTS now, with game:')
  // console.log(game)
  // console.log('and thisPlayer:')
  // console.log(thisPlayer)
  const [clicked, setClicked] = useState('')
  const [newRoundClicked, setNewRoundClicked] = useState(false)
  const [score, setScore] = useState(0)
  const [countdown, setCountdown] = useState(5)

  let thisRound = game.rounds[game.rounds.length - 1]

  let isAdmin = game.host.name == thisPlayer.name

  const newRound = () => {
    // console.log('About to send Start for thisPlayer.name: ' + thisPlayer.name,'score: ',score)
    // console.log("I'm in newRound method")

    setNewRoundClicked(true)
    sendMessage({
      event: 'newRound',
      player: { name: thisPlayer.name, score: score },
    })
  }

  const playerReady = () => {
    // setThisPlayer({ ...thisPlayer, ready: true })
    // sendMessage({ event: 'playerReady', player: { name: thisPlayer.name } })
    setNewRoundClicked(true)
  }
  //checks if isGameStateRoom and when it is true and starts countdown.
  useEffect(() => {
    if (countdown > 0 && isGameStateRoom) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)

      return () => clearInterval(timer)
    } else {
      setCountdown(5)
    }
  }, [isGameStateRoom])

  return (
    <div className="results">
      <div className="header">
        <div className="info-container">
          <h3 className="room-info">
            <p>Oda: {game.roomCode || '3ASX'}</p>
            <p>Başkan: {game.host.name}</p>
          </h3>
          <div className="player_info">
            <p className="player_name">{thisPlayer.name}</p>
            <img className="player_img" src={thisPlayer.avatar} />
          </div>
        </div>
      </div>
      <div className="results-container">
        <div className="results-room-info">
          <p>El Sayısı: {game.rounds.length}</p>
        </div>
        <br></br>
        <div className="results__sentence-container">
          <div className="results__sentence">
            {game.rounds[game.rounds.length - 1].sentence.map((word, i) => {
              if (word.type === 'text') {
                //Plain old text
                return <span key={i}>{word.value}</span>
              } else if (word.type === 'question') {
                //Get the player color from word.player post colorless if ai
                if (word.player.ai) {
                  return <span key={i}>{word.answer}</span>
                } else {
                  return (
                    <span key={i} className="mytooltip">
                      <span
                        className="tooltip-item"
                        style={{
                          color: `#${word.player.color}`,
                        }}
                      >
                        {word.answer}
                      </span>
                      <span className="tooltip-content">
                        <img src={word.player.avatar} />
                        <span
                          className="tooltip-text"
                          style={{
                            color: `#${word.player.color}`,
                          }}
                        >
                          {word.player.name}
                        </span>
                      </span>
                    </span>
                  )
                }
              }
            })}
          </div>
        </div>
        <br />
        {/* Vote icons */}
        <div className="results__vote-container">
          <p>Puanla!</p>
          <div id="voteIcons">
            <FontAwesomeIcon
              className={`fa-dizzy`}
              icon={clicked === 'fa-dizzy' ? fasFaDizzy : farFaDizzy}
              onClick={() => {
                setClicked('fa-dizzy')
                setScore(1)
              }}
            />
            <FontAwesomeIcon
              className={`fa-frown`}
              icon={clicked === 'fa-frown' ? fasFaFrown : farFaFrown}
              onClick={() => {
                setClicked('fa-frown')
                setScore(2)
              }}
            />
            <FontAwesomeIcon
              className={`fa-meh`}
              icon={clicked === 'fa-meh' ? fasFaMeh : farFaMeh}
              onClick={() => {
                setClicked('fa-meh')
                setScore(3)
              }}
            />
            <FontAwesomeIcon
              className={`fa-smile`}
              icon={clicked === 'fa-smile' ? fasFaSmile : farFaSmile}
              onClick={() => {
                setClicked('fa-smile')
                setScore(4)
              }}
            />
            <FontAwesomeIcon
              className={`fa-laugh`}
              icon={clicked === 'fa-laugh' ? fasFaLaugh : farFaLaugh}
              onClick={() => {
                setClicked('fa-laugh')
                setScore(5)
              }}
            />
          </div>
        </div>
        {/* at button click action set it disabled and remove pointerEvents with newRoundClicked useState   */}
        <div className="results__bottom-container">
          <button
            className="results-button"
            onClick={isAdmin ? newRound : playerReady}
            disabled={newRoundClicked || isGameStateRoom}
            style={{
              pointerEvents: (newRoundClicked || isGameStateRoom) && 'none',
            }}
          >
            {!isGameStateRoom ? 'Yeniden Oyna' : '...' + countdown + '...'}
            {/* {isGameStateRoom && 'Başlıyor...'} */}
            {/* {newRoundClicked && 'Başlıyor...'} */}

            {/* {isGameStateRoom && 'Başlıyor...'} */}
          </button>
          <div className="results__bottom-info">
            {!isAdmin && newRoundClicked && !isGameStateRoom && (
              <p>Diğer oyuncular bekleniyor...</p>
            )}
            {isGameStateRoom && <p>Oyun Başlıyor!</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Result
