import React from 'react'

export default class Ads extends React.Component {
  componentDidMount() {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }

  render() {
    return (
      <ins
        class="adsbygoogle"
        //style for setting height and width to show ad
        style={{ display: 'block', width: '100vw', height: '100vh' }}
        data-ad-client="ca-pub-5713716097274335"
        data-ad-slot="1573783302"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    )
  }
}
