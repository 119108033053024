import './App.css'
import { useState, useEffect } from 'react'
import Lobby from './components/lobby'
import Welcome from './components/welcome'
import Room from './components/room'
import Result from './components/result'

import Ads from './components/ad'
// import websocketurl from './components/welcome'

function App() {
  const [game, setGame] = useState({ state: 'welcome' })
  const [thisPlayer, setThisPlayer] = useState({
    name: 'Randomonius',
    ready: false,
    alive: false,
  })
  const [ws, setWs] = useState(null)
  const [answer, setAnswer] = useState('')
  const [isGameStateRoom, setIsGameStateRoom] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  // Function to establish the WebSocket connection

  // const isVisible = usePageVisibility();

  // Change the title based on page visibility
  // if (isVisible) {
  //   document.title = "Active";
  //   if (ws) {
  //     document.querySelector('h1').innerText += "|"
  //     //console.log("You have a ws, and we should check the state")
  //     switch (ws.readyState) {
  //       case 0:
  //         //console.log("----ws: connecting");
  //         document.querySelector('h1').innerText = "There is a ws. 0"
  //         break;
  //       case 1:
  //         //console.log("----ws: open");
  //         document.querySelector('h1').innerText = "There is a ws. 1"
  //         break;
  //       case 2:
  //         //console.log("----ws: closing");
  //         document.querySelector('h1').innerText = "There is a ws. 2"
  //         break;
  //       case 3:
  //         //console.log("----ws: closed");
  //         document.querySelector('h1').innerText = "There is a ws. 3"
  //         //console.log(ws.url)
  //         //console.log(game.roomCode)
  //         //console.log(ws.url.replace('new', game.roomCode))
  //         connectWebSocket(ws.url.replace('new', game.roomCode))
  //         //console.log("----ws: should be reconnected");
  //         break;
  //     }
  //   } else {
  //     if (game.state == 'welcome') {
  //       //console.log("weleecome, you don't have a ws")

  //     }
  //     if (game.state !== 'welcome') {
  //       //we should reconnect
  //     }

  //   }
  // } else {
  //   document.title = "Inactive";
  //   //disconnect right away. what gives.
  //   ws.close(4069)
  // }

  // async function checkAlive() {
  //   ws.addEventListener('message', async (msg) => {
  //     //console.log('See if we have the pong')
  //     let data = await JSON.parse(msg.data)
  //     //console.log(data.event)
  //     if (data.event == "pong") {
  //       thisPlayer.alive = true;
  //       setThisPlayer({thisPlayer})
  //     } else {
  //       thisPlayer.alive = false;
  //       setThisPlayer({thisPlayer})
  //     }
  //     //console.log(thisPlayer.alive)
  //   })
  //   //console.log("i'm bottom")
  //   await ws.send(JSON.stringify({event: "ping"}))

  // }

  useEffect(() => {
    if (window.navigator.userAgent.includes('iPhone')) {
      // alert("let me know if you're not an iPhone user")

      if (document.visibilityState === 'hidden') {
        document.title = 'Inactive'
        //disconnection management
        //console.log("hidden")
        thisPlayer.alive = false
        //console.log(thisPlayer)

        // if (ws) {
        //   // document.querySelector('h1').innerText = "There is ws."
        // } else {
        //   // document.querySelector('h1').innerText = "NO ws."
        // }

        // const currentDate = new Date();
        // // Get the current hour and format it as two digits
        // const hours = currentDate.getHours().toString().padStart(2, '0');
        // // Get the current minute and format it as two digits
        // const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        // // Get the current second and format it as two digits
        // const seconds = currentDate.getSeconds().toString().padStart(2, '0');
        // // Combine the hours and minutes in the desired format
        // const currentTime = `${hours}:${minutes}:${seconds}`;
        // //console.log(currentTime);
        // document.querySelector('h1').innerText = "hid:" + currentTime
      }
      if (document.visibilityState === 'visible') {
        //ReConnect
        document.title = 'Active'
        // checkAlive()

        // document.querySelector('h1').innerText += "|" + counter
        //console.log("this player when we get back to visible:")
        //console.log(thisPlayer)
        if (ws) {
          // alert(Date.now());
          // alert(ws.url.replace('new', game.roomCode))
          sendMessage({ event: 'ping' })
          //console.log("sent ping")

          // if (thisPlayer.alive) {
          //   alert("we're still live!")
          // }

          setTimeout(() => {
            //console.log("time out")
            //console.log(thisPlayer)
            if (thisPlayer.alive) {
              // alert("we're still live!")
            } else {
              //reconnect
              ws.close()
              connectWebSocket(ws.url.replace('new', game.roomCode))
            }
          }, 5000)

          // alert(ws.readyState)

          // ws.close()
          // connectWebSocket(ws.url.replace('new', game.roomCode));
          //ping server, or reconnect directly.

          // document.querySelector('h1').innerText += "|" + ws.readyState
          // if (ws.readyState === 0) {
          //   //console.log("----ws: connecting");
          // } else if (ws.readyState === 1) {
          //   //console.log("----ws: open");
          // } else if (ws.readyState === 2) {
          //   //console.log("----ws: closing");
          // } else if (ws.readyState === 3) {
          //   //console.log("----ws: closed");
          //   //console.log(ws.url);
          //   //console.log(game.roomCode);
          //   //console.log(ws.url.replace('new', game.roomCode));
          //   connectWebSocket(ws.url.replace('new', game.roomCode));
          //   //console.log("----ws: should be reconnected");
          // }
        } else {
          // document.querySelector('h1').innerText = "NO ws."
          //console.log("This must be our first encounter. So, welcome.")
        }

        // Page is back in the foreground
        // Reconnect WebSocket or resume communication
        //Currently, this works for tab changes, as well as app changes.
        //screen lock changes works too.
        // //console.log("Back in visible, and ws is:")
        // //console.log(ws)
        // const currentDate = new Date();
        // // Get the current hour and format it as two digits
        // const hours = currentDate.getHours().toString().padStart(2, '0');
        // // Get the current minute and format it as two digits
        // const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        // // Get the current second and format it as two digits
        // const seconds = currentDate.getSeconds().toString().padStart(2, '0');
        // // Combine the hours and minutes in the desired format
        // const currentTime = `${hours}:${minutes}:${seconds}`;
        // //console.log(currentTime);
        // document.querySelector('h1').innerText = currentTime

        // //console.log(ws.readyState)
        //   if (typeof ws !== 'undefined' && ws !== null) {
        //     //console.log("Object exists")
        //     document.querySelector('h1').innerText = "Object exists"
        //     // WebSocket object exists
        //     if (ws.readyState === WebSocket.OPEN) {
        //       // WebSocket connection is still active and connected
        //       document.querySelector('h1').innerText += " Still connected "
        //       //console.log("Still connected!")
        //       sendMessage({
        //         event: 'alive',
        //       })
        //     } else {
        //       // WebSocket connection is closed or not yet open
        //       document.querySelector('h1').innerText += " -Closed or not yet open- "
        //       //console.log("Uh, oh, disconnected!")
        //       //console.log("roomcode:", game.roomCode)
        //       //console.log("roomcode:", game.roomCode)
        //       //console.log("roomcode:", game.roomCode)
        //       //console.log("roomcode:", game.roomCode)
        //       //console.log("roomcode:", game.roomCode)
        //       // //console.log(ws.url.replace('new', game.roomCode))
        //       //console.log("playerName:", thisPlayer.name)
        //       //console.log("playerName:", thisPlayer.name)
        //       //console.log("playerName:", thisPlayer.name)
        //       //console.log("playerName:", thisPlayer.name)
        //       //console.log("playerName:", thisPlayer.name)
        //       // connectWebSocket(ws.url.replace('new', game.roomCode))
        //       sendMessage({
        //         event: 'died',
        //       })
        //     }
        // }
      }
    }
  }, [document.visibilityState])

  //Connect to websocket and listen to messages and errors
  const connectWebSocket = (websocketurl) => {
    const url = websocketurl
    const ws = new WebSocket(url)
    ws.addEventListener('open', () => {
      //console.log("Opened websocket.")
      // setThisPlayer({...thisPlayer, alive: true}) // For some reason, it shits this player back to default.
      // //console.log("this player: ")
      // //console.log(thisPlayer)
    })

    ws.addEventListener('message', async (msg) => {
      //console.log('++++ Incoming ++++ Message ++++ ')
      //console.log(await JSON.parse(msg.data))
      let data = await JSON.parse(msg.data)

      //////alert/////
      if (data.event == 'debug') {
        console.log('debug')
      }

      ////////////enteredRoom////////////
      if (data.event == 'enteredRoom') {
        //console.log('enteredRoom event received, roomCode is:', data.roomCode, "username is:", data.username)
        //Sertaç, burda /room/roomCode adresine yönlendirebiliriz.
        //Sertaç, render lobby now.
        //console.log('Game when I entered is: ')
        //console.log(data.game)
        // data.game.thisPlayer = { name: data.username, ready: false }
        data.game.players.forEach((player) => {
          if (player.name == data.username) {
            setThisPlayer({ ...player, alive: true })
          }
        })
        // alert(thisPlayer.state)
        //console.log("before setting the game:")
        //console.log(data.game)
        setGame(data.game)
        //find the player with this username, and attach it as this Player

        //Maybe we should NOT send player joined if the data.game.state is room or result.
        ws.send(
          JSON.stringify({
            event: 'playerJoined',
            player: { name: data.username },
          })
        )
        //Acaba, game.state'i değişterek mi yapalım yine, yoksa burda url mi değiştirelim?
      }

      //////////pong////////////////////
      if (data.event == 'pong') {
        //console.log("pong geldiğinde thisplayer:")
        //console.log(thisPlayer)
        // thisPlayer.alive = true
        setThisPlayer({ ...thisPlayer, alive: true })
        //console.log("pong ile güncellenmiş thisPlayer:")
        //console.log(thisPlayer)
      }

      ////////////playerJoined////////////
      if (data.event == 'playerJoined') {
        // //console.log('playerJoined event received')
        //Change the URL to /room/roomCode
        // if (game.roomCode == 'new') {
        //   game.roomCode = data.game.roomCode
        // }
        // if (data.game.state == 'room' || data.game.state == 'result') {
        //   data.game.state = 'lobby'
        //   thisPlayer.state = 'waitingRoom'
        //  }
        //console.log("game players")
        //console.log(data.game.players)
        // console.log("playerJoined'de")
        // console.log(data.game)
        setGame(data.game)
      }

      ////////////playerReady////////////
      if (data.event == 'playerReady') {
        //console.log('playerReady event received')
        //console.log('data.game.players.length')
        //console.log(data.game.players.length)
        //console.log('data.player')
        //console.log(data.player)
        //console.log('stringified Sdata.player')
        //console.log(JSON.stringify(data.player))
        if (data.player == thisPlayer.name) {
          //console.log("This player is.")
          setThisPlayer({ ...thisPlayer, ready: true })
        }

        setGame(data.game)
        //console.log('Updated the game for Ready, now it is: ')
        //console.log(game)
      }

      ////////////playerNotReady////////////
      if (data.event == 'playerNotReady') {
        //console.log('playerNotReady event received')
        //console.log(data.game.players)
        if (data.player == thisPlayer.name) {
          //console.log("This player is.")
          setThisPlayer({ ...thisPlayer, ready: false })
        }
        setGame(data.game)
        //console.log('Updated the game for NotReady, now it is: ')
        //console.log(game)
      }

      ////////////playerLeft////////////
      if (data.event == 'playerLeft') {
        // console.log("PLAYER LEFT")
        // console.log("data:", data)
        // console.log(data)
        //Find the data.player in the game.players array and remove it
        //Or just set the game?
        //console.log('playerLeft event received')
        setGame(data.game)
        //Sertaç pop the user from the array
        // Sertac, update the code to see if this user is the host by checking the players[0], if so, display Host specific actions like Start the game.
      }

      ////////////newRound////////////
      if (data.event == 'newRound') {
        //If this player was afk before, now we can add them in.
        //console.log("newRound event geldiğinde bu oyuncu:")
        //console.log(thisPlayer)
        // if (thisPlayer.state == "waiting") {
        //   //console.log("we got the new round so switching to playing mode")
        //   setThisPlayer({...thisPlayer, state: "playing"})
        // }

        //console.log('newRound event received')
        //Sets the isGameStateRoom to true for conditioning lobby and result buttons disable
        setIsGameStateRoom(true)

        // console.log(data.game, isGameStateRoom)
        setTimeout(() => {
          setGame(data.game)
        }, 5000)
      }

      ////////////results////////////
      if (data.event == 'results') {
        //Sertaç, burası eskisinden kalma, tamamen yenileyeceğiz.
        //Her oyuncu cevabını verince, server bu eventi gönderiyor.
        //Bu eventi aldığımızda, game.state'i 'result' olarak geliyo.
        //console.log('results event received')
        //console.log(data)
        //console.log("result event geldiğinde bu oyuncu:")
        //console.log(thisPlayer)
        // setThisPlayer({...thisPlayer, state: 'playing'})
        setGame(data.game)
      }

      //if websocket connection fails, show an alert message
      if (data.event == 'connectionFailed') {
        //console.log('connectionFailed event received')
      }
      if (data.event == 'error') {
        //console.log('error event received:')
        //console.log(data.message)
        //sertaç this is where we would have room not found type of stuff. manage those.
      }
    })

    ws.addEventListener('error', (msg) => {
      // WebSocket connection is closed
      //console.log('Bir error gördüm sanki')
      //console.log(msg)
    })

    ws.addEventListener('close', (msg) => {
      // alert("websocket closed")
      //console.log('Websocket kapandı.')
      //console.log(msg)
      // Check if the connection was closed due to a declined request
      setErrorMessage(msg.reason)
      //errorMessage can be 'Room not found' or 'Invalid room code'
      if (msg.reason === 'Room not found') {
        //Sertaç, bu error'u alıp toastlamak lazım önyüzde.
        //console.log('WebSocket connection closed, because:', msg.reason)
      } else if (msg.reason === 'Invalid room code') {
        //console.log('WebSocket connection closed because:', msg.reason)
      } else {
        // WebSocket connection is closed
        //console.log('WebSocket connection closed')
      }
    })

    setWs(ws)
  }
  //checks if the game is ongoing if not sets the isGameStateRoom
  useEffect(() => {
    if (game.state !== 'room') {
      return setIsGameStateRoom(false)
    }
  }, [game.state])

  // Function to send a message using the WebSocket instance
  const sendMessage = (message) => {
    if (ws && ws.readyState === 1) {
      ws.send(JSON.stringify(message))
    }
  }

  switch (
    game.state //burası useEffect olmadan da dinliyor değişiklikleri.
  ) {
    case 'ad':
      return <Ads></Ads>
    case 'lobby':
      return (
        <Lobby
          game={game}
          setGame={setGame}
          sendMessage={sendMessage}
          thisPlayer={thisPlayer}
          setThisPlayer={setThisPlayer}
          connectWebSocket={connectWebSocket}
          ws={ws}
          setWs={setWs}
          isGameStateRoom={isGameStateRoom}
        />
      )
    case 'room':
      return (
        <Room
          game={game}
          setGame={setGame}
          sendMessage={sendMessage}
          thisPlayer={thisPlayer}
          setThisPlayer={setThisPlayer}
          connectWebSocket={connectWebSocket}
          ws={ws}
          setWs={setWs}
          className="room"
        />
      )
    case 'result':
      return (
        <Result
          game={game}
          setGame={setGame}
          sendMessage={sendMessage}
          thisPlayer={thisPlayer}
          setThisPlayer={setThisPlayer}
          answer={answer}
          setAnswer={setAnswer}
          isGameStateRoom={isGameStateRoom}
          ws={ws}
          setWs={setWs}
        />
      )
    // case 'results':
    // return <Results game={game} />
    default:
      return (
        <Welcome
          game={game}
          setGame={setGame}
          thisPlayer={thisPlayer}
          setThisPlayer={setThisPlayer}
          connectWebSocket={connectWebSocket}
          ws={ws}
          setWs={setWs}
          errorMessage={errorMessage}
        />
        // <Game
        //   game={game}
        //   setGame={setGame}
        //   sendMessage={sendMessage}
        //   thisPlayer={thisPlayer}
        //   setThisPlayer={setThisPlayer}
        // />
      )
  }
  // //console.log("gameInitialData.game.state")
  // //console.log(gameInitialData.game.state)
  // switch (gameInitialData.game.state) {
  //   case 'lobby':
  //     return <Lobby game={game} />
  //   case 'room':
  //     return <Room game={game} />
  //   // case 'results':
  //   //   return <Results game={game} />
  //   default:
  //     return <Welcome game={game} setGame={setGame} />;
  // }
}

export default App
