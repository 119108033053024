import React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { ToastContainer, toast } from 'react-toastify'

import './room.scss'
import { nanoid } from 'nanoid'

const Room = ({ game, sendMessage, thisPlayer, ws, setWs }) => {
  let thisRound = game?.rounds[game.rounds.length - 1]
  const [isAnswered, setIsAnswered] = useState(false)
  const {
    register,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      answer: '',
    },
    mode: 'onChange',
  })
  let playerAvatar = ''
  let sentence = thisRound.sentence
  let playerWord = {}

  const formSchema = {
    answer: {
      required: {
        value: true,
        message: 'Kullanıcı adı zorunludur.',
      },

      minLength: {
        value: 2,
        message: 'En az 2 harf giriniz.',
      },
      transform: (value) => value.trim(),
    },
  }

  const handleAnswer = (event) => {
    event.preventDefault()
    let thisAnswer = getValues('answer')
    // console.log(thisAnswer)
    // console.log('About to send Answer for thisPlayer.name: ', thisPlayer.name)
    sendMessage({
      event: 'answer',
      player: { name: thisPlayer.name, answer: thisAnswer },
    })
    setIsAnswered(true)
  }

  // console.log('We are in room now, with game:')
  // console.log(game)
  // console.log('and thisPlayer:')
  // console.log(thisPlayer)
  // console.log('and thisPlayer State:')
  // console.log(thisPlayer.state)

  //  let sentence = [
  //    {
  //      type: 'text1',
  //      value: 'günlerden bir gün',
  //    },
  //    {
  //      type: 'question',
  //      value: 'kim',
  //      clue: 'bülent',
  //      player: 'volkan',
  //    },
  //    {
  //      type: 'text2',
  //      value: ',',
  //    },
  //  ]

  //check if answer is not null or "" with useEffect if so set isAnswered to true
  // useEffect(() => {
  //   if (thisPlayer.answer !== null || thisPlayer.answer !== '') {
  //     isAnswered = true
  //     playerAvatar = 'none'
  //   }
  // }, [getValues('answer')])

  // useEffect(() => {
  //   //check if game.state change to 'result' if so send a toast message
  //   if (game.state === 'result') {
  //     toast.success('Cevaplar gönderildi!')
  //   }
  // }, [game.state])

  return (
    <div className="room">
      <div className="room__main-container">
        <div className="header">
          <div className="info-container">
            <h3 className="room-info">
              <p>Oda: {game.roomCode || '3ASX'}</p>
              <p>Başkan: {game.host.name}</p>
            </h3>
            <div className="player_info">
              <p className="player_name">{thisPlayer.name}</p>
              <img className="player_img" src={thisPlayer.avatar} />
            </div>
          </div>
        </div>
        {/* <div className="room__game-container"> */}
        <div className="sentence-container">
          <div className="sentence">
            {sentence.map((word, i) => {
              // console.log(word)
              if (word.type === 'text') {
                //eğer text ise noktalamamı diye kontrol et
                //noktalama değilse bas
                if (
                  !(
                    word.value.trim().length == 1 &&
                    word.value.trim().match(/\p{P}/u)
                  )
                ) {
                  return (
                    <span key={i} className="player_text">
                      {word.value}
                    </span>
                  )
                }
              } else if (word.type === 'question') {
                //Punctiation coming up after this question
                if (!word.player) {
                  //this is an AI with punctuation after
                  //nanoid ile random id generate edip linke yerleştiriyoruz.
                  const backgroundImage = `url(https://api.dicebear.com/6.x/adventurer-neutral/svg?seed=${nanoid(
                    10
                  )}&radius=50)`
                  return (
                    <div key={i} className="room__player-punctuation-container">
                      <div className="room__player">
                        <input
                          type="text"
                          style={{ backgroundImage: backgroundImage }}
                          disabled
                        />
                        <div className="room__player-text--below">
                          <p>{word.value}</p>
                        </div>
                      </div>
                      {sentence[i + 1].type === 'text' &&
                        sentence[i + 1].value.trim().length == 1 && (
                          <span className="player_text">
                            {sentence[i + 1].value}
                          </span>
                        )}
                    </div>
                  )
                } else if (word.player.name === thisPlayer.name) {
                  //MY QUESTION this is me
                  // console.log('Benim sorum: ' + word.value)
                  //burada myQuestion içine set ediyoruz playera ait wordu.
                  playerWord = word
                  playerAvatar = `url(${word.player.avatar})`

                  return (
                    <div key={i} className="room__player-punctuation-container">
                      <ToastContainer></ToastContainer>

                      <div className="room__player">
                        <input
                          id="avatar"
                          type="text"
                          style={{ backgroundImage: playerAvatar }}
                          {...register('answer')}
                          disabled
                        />
                        <div className="room__player-text--below">
                          <p>{word.value}</p>
                        </div>
                      </div>
                      {sentence[i + 1].type === 'text' &&
                        sentence[i + 1].value.trim().length == 1 && (
                          <span className="player_text">
                            {sentence[i + 1].value}
                          </span>
                        )}
                    </div>

                    // <span className="input__box">
                    //   <input
                    //     className="input__game--1"
                    //     type="text"
                    //     placeholder="Buraya cevabı yaz"
                    //     name={word.value}
                    //     {...register('answer')}
                    //     autoFocus
                    //   />
                    // </span>
                  )
                } else {
                  //ANOTHER player
                  playerAvatar = `url(${word.player.avatar})`
                  return (
                    <div key={i} className="room__player-punctuation-container">
                      <div className="room__player">
                        <input
                          type="text"
                          style={{ backgroundImage: playerAvatar }}
                          disabled
                        />
                        <div className="room__player-text--below">
                          <p>{word.value}</p>
                        </div>
                      </div>
                      {sentence[i + 1].type === 'text' &&
                        sentence[i + 1].value.trim().length == 1 && (
                          <span className="player_text">
                            {sentence[i + 1].value}
                          </span>
                        )}
                    </div>
                  )
                }
              }
            })}
          </div>
        </div>

        {!game.rounds[game.rounds.length - 1].players.some(
          (player) => player.name === thisPlayer.name
        ) ? (
          <p className="room__waiting-alert">Yeni el bekleniyor...</p>
        ) : (
          // !isAnswered && (
          <div className="room__bottom">
            {' '}
            <form
              className="room__bottom-container"
              disabled={isAnswered}
              onSubmit={handleAnswer}
            >
              <div className="room__input-container--left">
                <input
                  className="room__input-bottom"
                  type="text"
                  {...register('answer', formSchema.answer)}
                  placeholder={`'${
                    playerWord.value
                  }' için bir ${playerWord?.clue?.toLowerCase()} uydurun.`}
                  autoFocus
                  disabled={isAnswered}
                />{' '}
              </div>
              <div className="room__input-container--right">
                <button
                  className="room__button "
                  disabled={isAnswered || !isValid}
                  style={{
                    pointerEvents: isAnswered && 'none',
                  }}
                >
                  {isAnswered ? 'Cevaplandı' : 'Cevapla'}
                </button>
              </div>
            </form>
            <div className="room__instructions">
              {!isAnswered ? (
                <p>
                  Örneğin: <span>{playerWord.eg}</span>
                </p>
              ) : (
                <p>
                  Cevabınız alındı. Diğer oyuncuların cevaplaması bekleniyor.
                </p>
              )}
            </div>
          </div>
          // )
        )}
      </div>
      {/* </div> */}
    </div>
  )
}

export default Room
